import { safeFetch } from '@utils/safeFetch';

type Context = {
  id: string;
  text: string;
};

export type Feature = {
  text: string;
  'text_en': string;
  'place_type': string[];
  'place_name': string;
  context: Context[];
  center: [number, number];
};

type MapboxGeocodeResponse = {
  features: Feature[];
};

export type AreaObject = {
  areaId: number;
  areaType?: string;
  name?: string;
  city?: string;
  county?: string;
  state?: string;
  country?: string;
  continent?: string;
  fullname?: string;
  accommodationsCount?: string;
};

// We always want the english version of the area to help on the
// exact matching of our own areas.
const DEFAULT_LANGUAGE = 'en';
const getLanguages = () => [...(navigator?.languages || [])]
  // mapbox will error if duplicate locales are sent
  .filter((l) => l !== DEFAULT_LANGUAGE)
  .concat(DEFAULT_LANGUAGE);

export const geocodeMapbox = async (text: string) => {
  const key = process.env.MAPBOX || '';
  const types = 'country,region,postcode,district,place,locality,neighborhood,address,poi';
  const mapboxOrigin = 'https://api.mapbox.com';
  const limit = 5;
  const languages = getLanguages();

  const url = new URL(mapboxOrigin);
  // ignore semicolons as detailed in https://docs.mapbox.com/api/search/geocoding/#forward-geocoding
  url.pathname = `/geocoding/v5/mapbox.places/${text.replace(',', ' ')}.json`;
  const search = new URLSearchParams();
  search.set('access_token', key);
  search.set('autocomplete', 'true');
  search.set('types', types);
  search.set('limit', `${limit}`);
  search.set('language', languages.join(','));
  url.search = `?${search}`;

  const data = await safeFetch<MapboxGeocodeResponse>(url);
  return data;
};
