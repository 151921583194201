import React from 'react';
import { AreaInfo } from '@apolloCli/queries/areas';
import { AutoCompleteOption } from '@components/dataEntry/AutoComplete/AutoCompleteListItems';
import { formatNumber } from '@utils/numbers';
import clsx from 'clsx';

import styles from './RelatedAreas.module.scss';

type Props = {
  options: AutoCompleteOption<AreaInfo>[];
  onClickItem: (picked: AutoCompleteOption<AreaInfo>) => void;
  cursor?: number;
};

export const RelatedAreas = ({ options, onClickItem, cursor }: Props) =>
  options.length > 0 && (
    <div className={clsx(styles.Container)}>
      <table>
        <thead>
          <tr className={styles.ListLabels}>
            <th className={styles.TitleTable}>Related Results</th>
            <th className={styles.ListTitleTable}># of listings</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, i) => (
            <tr
              onClick={() => onClickItem(option)}
              className={clsx(styles.List, cursor === i && styles.Active)}
              key={option.key}
            >
              <td>{option.text}</td>
              <td className={styles.Listings}>
                {option.payload?.nListings ? formatNumber(option.payload?.nListings) : '--'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
