/* eslint-disable default-param-last */
const cache = {};

export const formatCurrency = (currency = 'USD', amount: number, maximumFractionDigits?: number) => {
  const id = `${currency}-${maximumFractionDigits}`;
  const formatter =
    (cache as any)[id] ||
    ((cache as any)[id] =
      maximumFractionDigits === 0
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase(),
            minimumFractionDigits: 0,
            maximumFractionDigits,
          })
        : new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase(),
            maximumFractionDigits,
          }));
  return formatter.format(amount);
};

export const formatNumber = (number: number) => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });
  return formatter.format(number);
};

export const padNumber = (number: number, length: number) => {
  let str = `${number}`;
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
};

export const lastTwoDigits = (number: number) => {
  const toString = `${number}`;
  return toString.substring(toString.length - 2);
};

export const genFormatter = (type?: 'decimal' | 'currency' | 'percent') => {
  const fn = (inputValue: any) => {
    if (inputValue === undefined || inputValue === null || inputValue === '') return '';
    if (!type) return String(inputValue);

    const number = Number(inputValue);

    if (type === 'percent') {
      return `${number}%`;
    }

    try {
      const currencyStr = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return currencyStr;
    } catch (e) {
      return number;
    }
  };

  return fn;
};

export const genFormatterV2 = (type?: 'currency' | 'percent' | 'text') => {
  const fn = (inputValue: any) => {
    if (inputValue === undefined || inputValue === null || inputValue === '') return '';
    if (!type) return String(inputValue);

    const number = Number(inputValue);

    if (type === 'percent') {
      return `${number}%`;
    }

    if (type === 'text') {
      return `${number} nights`;
    }

    try {
      const currencyStr = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return currencyStr;
    } catch (e) {
      return number;
    }
  };

  return fn;
};
