import React from 'react';
import clx, { ClassValue } from 'clsx';
import { CardVariations } from '@pageComponents/HomePage/CardLink/CardLink.logic';

import styles from './CardLink.module.scss';

type Props = {
  type: 'Market' | 'Score' | 'Competitive' | 'Enterprise';
  className?: ClassValue;
  onClickLink: () => void;
};

export const CardLink: React.FC<Props> = ({ type, onClickLink, className }) => {
  const objClassname = {
    [styles.MarketCard]: type === 'Market',
    [styles.ScoreCard]: type === 'Score',
    [styles.CompetitiveCard]: type === 'Competitive',
  };
  const { title, description, icon, image, longDescription, isNewCard, isBeta } = CardVariations[type];

  const classIcon = (() => {
    switch (type) {
      case 'Market':
        return styles.MarketIcon;
      case 'Score':
        return styles.ScoreIcon;
      default:
        return styles.CompetitiveIcon;
    }
  })();

  return (
    <div className={clx(styles.Container, objClassname, className)} onClick={onClickLink}>
      {isNewCard && <img className={styles.CardNewBadge} src="/images/new-badge.svg" alt="new-badge" />}

      <div className={styles.TabletImage}>
        <img src={image} alt="card-image" />
      </div>

      <div className={styles.IconTitleContainer}>
        <img className={clx(styles.Icon, classIcon)} src={icon} />
        <div className={styles.Title}>
          {title}
          <span className={styles.RightArrow}>&gt;</span>
          {isBeta && <div className={styles.IconTitleBeta}>BETA</div>}
        </div>
      </div>

      <div className={styles.Description}>
        <span className={styles.short}>{description}</span>
        <span className={styles.long}>{longDescription}</span>
      </div>
    </div>
  );
};
