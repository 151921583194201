import { Button } from '@components/general/Button/Button';
import clsx, { ClassValue } from 'clsx';
import React from 'react';

import styles from './Banner.module.scss';

type Props = {
  className: ClassValue;
  label: string;
  paragraph: string;
  href: string;
  img: string;
  buttonText: string;
};

export const Banner: React.FC<Props> = ({ className, label, paragraph, href, img, buttonText }) => (
  <div className={clsx(styles.Container, className)}>
    <img className={styles.Image} src={img} alt="" />
    <div className={styles.TextContainer}>
      <label>{label}</label>
      <p>{paragraph}</p>
    </div>
    <Button type="link" theme="white" href={href} target="_blank" className={styles.Button}>
      {buttonText}
      <span>&gt;</span>
    </Button>
  </div>
);
