import { NamedPoolConfig } from 'pg-txclient';

const validations = ({ host }: NamedPoolConfig) => {
  if (host && host.indexOf('amazonaws.com') > -1 && process.env.NODE_ENV === 'test') {
    throw new Error('HALT! Connecting to production databases in test environments');
  }
};

export const configs: { [key: string]: NamedPoolConfig } = {
  data: {
    name: 'dataPG',
    host: process.env.DATA_PG_HOST,
    user: process.env.DATA_PG_USER,
    password: process.env.DATA_PG_PASSWORD,
    database: process.env.DATA_PG_DATABASE,
    connectionTimeoutMillis: Number(process.env.DB_CONNECTION_TIMEOUT),
    max: Number(process.env.MAX_DB_CONNECTIONS),
    min: Number(process.env.MIN_DB_CONNECTIONS),
    validations,
  },
};

export const BASE_PATH = '/';
export const SCORE_PATH = '/host/score';
export const HISTORICAL_PATH = '/investor/historical';
export const ENTERPRISE_PATH = '/enterpriseV2';
