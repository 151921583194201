import React, { useEffect, useState } from 'react';
import { ClassValue } from 'clsx';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GetAreaInfo,
  GeocodedResult,
  GetMatchAreas,
  GET_AREA,
  GET_MATCH_AREAS,
  AreaInfo,
} from '@apolloCli/queries/areas';
import { AutoCompleteOption } from '@components/dataEntry/AutoComplete/AutoCompleteListItems';
import { AutoComplete2 } from '@components/dataEntry/AutoComplete/AutoComplete2';
import { useAdvanceSearch } from '@hooks/useAdvanceSearch';
import { GET_NEARBY_AREAS, NearbyArea, NearbyAreasType } from '@apolloCli/queries/nearbyAreas';
import { appEvent } from '@utils/analytics';
import { useUiFilters } from '@hooks/useUiFilters';
import { guidedTourVar } from '@apolloCli/policies/uiPolicy';
import { performTourFunction, TourFunction, Tours } from '@components/tours/utils';

import { RelatedAreas } from './RelatedAreas/RelatedAreas';
import { Feature, geocodeMapbox, AreaObject } from './GeocodeAutocomplete.logic';

type Props = {
  areaId?: number;
  className?: ClassValue;
  onPickArea: (area: AreaObject | null) => void;
  emptyByDefault?: boolean;
  hideChevron?: boolean;
  includeAreaType?: boolean;
  placeholder?: string;
};

// TODO: Unify AreaObject and AreaInfo?

const USER_STOPPED_TYPING_MS = 300;
let typingTimer: ReturnType<typeof setTimeout> | null;

const GeocodedAutocomplete: React.FC<Props> = ({
  areaId,
  className,
  onPickArea,
  emptyByDefault,
  hideChevron,
  includeAreaType = false,
  placeholder,
}) => {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<AutoCompleteOption<Feature>[]>([]);
  const [selectedOption, setSelectedOption] = useState<AutoCompleteOption<Feature>>();
  const [lastOption, setLastOption] = useState('');
  const [suggestedOptions, setSuggestedOptions] = useState<AutoCompleteOption<AreaInfo>[]>([]);
  const [autocompleteText, setAutocompleteText] = useState('');
  const [stoppedTyping, setStoppedTyping] = useState(false);
  const { handleRecentView } = useAdvanceSearch();
  const { providerId } = useUiFilters();
  const guidedTourState = useReactiveVar(guidedTourVar);

  const { data: areaData } = useQuery<GetAreaInfo>(GET_AREA, {
    variables: { areaId },
    skip: !areaId,
  });

  const { data: nearbyAreasData, loading: nearbyAreasLoading } = useQuery<NearbyAreasType>(GET_NEARBY_AREAS, {
    variables: {
      areaId,
    },
    skip: !areaId,
  });

  // TODO: handle errors
  // TODO: handle loading state
  const { data: matchAreasData, loading } = useQuery<GetMatchAreas>(GET_MATCH_AREAS, {
    variables: {
      geocodedResults: options.map((o) => {
        const { text_en: areaNameEN } = o.payload || {};
        const [lon, lat] = o.payload?.center || [];
        const areaTypes = o.payload?.place_type || [];
        if (Number.isNaN(lon) || Number.isNaN(lat)) {
          throw new Error(`No center coordinates found in the option payload`);
        }

        return {
          areaNameEN,
          areaTypes,
          point: {
            // TODO: maybe we use [lon, lat] to reduce the payload size?
            // TODO: maybe we want the geometry.Point coordinates?
            lat,
            lon,
          },
        } as GeocodedResult;
      }),
      includeAreaType,
      // TODO: Follow the geocoding limit
    },
    skip: !searchText || options.length === 0 || !stoppedTyping,
  });

  const isLoading = loading && nearbyAreasLoading;

  const onPickAreaHandler = ({
    selectedAreaId,
    fullname,
    areaType,
  }: {
    selectedAreaId?: number;
    fullname?: string;
    areaType?: string;
  }) => {
    if (selectedAreaId) {
      const payload = {
        areaId: selectedAreaId,
        fullname,
        areaType,
      };
      const areaCenter = selectedOption?.payload.center;
      handleRecentView({
        areaId: selectedAreaId,
        text: fullname,
        payload: {
          center: areaCenter,
        },
      });
      onPickArea(payload);
    }
  };

  const formatNearbyAreas = (nearbyAreas: NearbyArea[] = []) =>
    nearbyAreas.map((area) => ({
      id: area.id,
      name: area.name,
      nListings: area.countActive?.find((count) => count.providerId === providerId)?.countActive || 0,
    }));

  useEffect(() => {
    async function fetchData() {
      try {
        if (!searchText) return;
        const { features } = await geocodeMapbox(searchText);
        setOptions(
          features.map((feat, i) => ({
            text: feat.place_name,
            key: i,
            payload: feat,
          })),
        );
      } catch (err) {
        // TODO: handle the FetchError
        // https://www.pivotaltracker.com/story/show/177405260
      }
    }

    fetchData();
  }, [searchText]);

  const [area] = areaData?.areas || [];
  const areaName = area?.fullName || area?.name;

  useEffect(() => {
    if (areaName && (autocompleteText !== areaName || autocompleteText !== lastOption)) {
      setAutocompleteText(areaName);
      setLastOption(areaName);
    }
  }, [areaName, autocompleteText, lastOption]);

  const reset = () => {
    // avoid querying unnecessary match areas
    setSearchText('');

    // avoid the selection match hub to trigger automatically
    setSelectedOption(undefined);
    setLastOption('');

    // avoid showing up the suggested options again
    setSuggestedOptions([]);
  };

  const pick = (areaInfo: AreaInfo) => {
    onPickAreaHandler({
      selectedAreaId: areaInfo.id,
      fullname: areaInfo.fullName || undefined,
      areaType: areaInfo.areaType,
    });
    appEvent('Visited Exact Area Match', {
      areaId: areaInfo.id,
      areaName: areaInfo.name,
    });
  };

  const { matchAreas } = matchAreasData || {};

  // selection match hub
  // once an option has been selected, it is responsible
  // to either pick the exact match or to set the
  // suggestion lists to the related matches.
  useEffect(() => {
    const key = Number(selectedOption?.key);
    if (selectedOption && key >= 0) {
      const { exact, related } = matchAreas?.[key] || {};
      if (exact) {
        reset();
        setAutocompleteText(selectedOption.text);
        // signal the option has been handled to close the items list
        selectedOption.selectionHandled = true;
        pick(exact);
      } else if (related?.length) {
        // avoid the selected option hijacks the input text after an
        // onblur and when no suggested option was selected.
        setSelectedOption(undefined);
        setSuggestedOptions(
          related
            .map((rel, i) => ({
              text: rel.fullName || rel.name,
              key: i,
              payload: rel,
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        );
        setOptions([]); // show the related areas
      } else if (matchAreas?.length) {
        setOptions([]);
      }
    }

    // Skipping pick as dependency for the useEffect results
    // in less executions
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [matchAreas, selectedOption]);

  const onChangeAutocomplete = (text: string) => {
    if (typingTimer) {
      clearTimeout(typingTimer);
      typingTimer = null;
    }

    typingTimer = setTimeout(() => {
      setStoppedTyping(true);
    }, USER_STOPPED_TYPING_MS);

    setStoppedTyping(false);
    setSuggestedOptions([]);

    if (text.length && text !== areaName) {
      setLastOption(text);
      setSearchText(text);
    }

    // cleared the input or lost focus, reset
    if (!text || text === areaName) {
      reset();
      setOptions([]);
    }
  };

  const onSelectOption = (option: AutoCompleteOption<Feature> | null) => {
    if (option?.text) {
      setLastOption(option.text);
    }
    if (option) setSelectedOption(option);
    if (guidedTourState?.id === Tours.TOP_PROPERTIES) {
      performTourFunction(TourFunction.NEXT, true);
    }
  };

  const onSelectSuggestion = (option: AutoCompleteOption<AreaInfo>) => {
    if (option.payload) {
      reset();
      setAutocompleteText(option.text);
      // signal the option has been handled to close the items list
      /* eslint-disable no-param-reassign */
      option.selectionHandled = true;
      pick(option.payload);
    }
  };

  const onClearHandler = () => {
    reset();
  };

  const onClickNearbyArea = (nearbyArea: NearbyArea) => {
    onPickAreaHandler({
      selectedAreaId: nearbyArea.id,
      fullname: nearbyArea.name || undefined,
    });
  };

  return (
    <AutoComplete2
      className={className}
      onClear={onClearHandler}
      isLoading={isLoading}
      options={options}
      onSelect={onSelectOption}
      SuggestionList={RelatedAreas}
      suggestedOptions={suggestedOptions}
      onSelectSuggestion={onSelectSuggestion}
      onChange={onChangeAutocomplete}
      text={lastOption || autocompleteText}
      hideItemsAfterPick={false}
      setInputValueAfterPick={false}
      nearbyAreasData={formatNearbyAreas(nearbyAreasData?.nearbyAreas)}
      currentArea={areaData?.areas[0]}
      onClickArea={onClickNearbyArea}
      emptyByDefault={emptyByDefault}
      hideChevron={hideChevron}
      placeholder={placeholder}
    />
  );
};

export default GeocodedAutocomplete;
