export class FetchError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

const safeParseJSON = async <T>(response: Response): Promise<T | null> => {
  try {
    return await response.json();
  } catch (_e) {
    return null;
  }
};

export const safeFetch = async <T>(url: string | URL, opts?: any): Promise<T> => {
  const options = { ...opts };
  if (opts && opts.body) {
    options.body = JSON.stringify(opts.body);
    options.method = opts.method && opts.method !== 'GET' ? opts.method : 'POST';
    options.headers = options.headers || {};
    options.headers['Content-Type'] = 'application/json';
  }
  const resp = await fetch(`${url}`, options);
  const data = await safeParseJSON<any>(resp);
  if (resp.ok || resp.redirected) return data;

  const textError = data && data.message ? data.message : '';
  throw new FetchError(textError, resp.status);
};
