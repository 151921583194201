import { ProviderEnum } from '@apolloCli/policies/uiPolicy';
import gql from 'graphql-tag';

export type CountActive = {
  countActive: number;
  providerId: ProviderEnum;
};

export type NearbyArea = {
  id: number;
  name: string;
  nListings: number;
  countActive?: CountActive[];
};

export type NearbyAreasType = {
  nearbyAreas: NearbyArea[];
};

export const GET_NEARBY_AREAS = gql`
  query getNearbyAreas($areaId: Int!, $nAreas: Int, $radius: Int) {
    nearbyAreas(areaId: $areaId, nAreas: $nAreas, radius: $radius) {
      id
      name
      nListings
      countActive {
        countActive
        providerId
      }
    }
  }
`;
