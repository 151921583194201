import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Dashboard } from '@layout/Dashboard/Dashboard';
import GeocodedAutocomplete from '@components/dataEntry/GeocodedAutocomplete/GeocodedAutocomplete';
import { Banner } from '@components/communication/Banner/Banner';
import { CardLink } from '@pageComponents/HomePage/CardLink/CardLink';
import { AreaObject } from '@pageComponents/Investor/HistoricalPage/HistoricalPage';
import { useLogin } from '@hooks/useLogin';
import { useUser } from '@hooks/useUser';
import { useDefaultArea } from '@hooks/useDefaultArea';
import useBreakpoint from '@utils/useBreakpoint';
import { marketingURLS } from '@utils/links';
import { DEFAULT_CITY_ID } from '@const/dashboard';
import { ENTERPRISE_PATH, HISTORICAL_PATH, SCORE_PATH } from 'src/const/configs';

import styles from './HomePage.module.scss';

type Props = {};

export const HomePage: React.FC<Props> = () => {
  const router = useRouter();
  const { DEFAULT_AREA_ID, loading: defaultAreaLoading } = useDefaultArea();
  const { logIn } = useLogin();
  const [path, setPath] = useState('');
  const { user, loading, isLogin } = useUser();
  const breakPoint = useBreakpoint();
  const isMobile = breakPoint === 'mobile';

  const onClickRedirect = (customPath: string) => () => {
    setPath(customPath);
  };

  const processAreaPicked = (area: AreaObject | null) => {
    if (area && area.areaId) {
      router.push('/investor/historical/[slug]', `/investor/historical/${area.areaId}`);
    }
  };

  useEffect(() => {
    if (!defaultAreaLoading && path) router.push(path);
  }, [path, defaultAreaLoading, router]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const openLogin = urlParams.has('login');
    if (openLogin && !loading && user === undefined) {
      logIn();
      router.replace('/');
    }
  }, [logIn, loading, user, router]);

  // 843104
  const defaultCity = DEFAULT_CITY_ID.toString();
  let area: string | number = DEFAULT_AREA_ID;
  let lastVisitedArea: string | null = '';

  if (typeof window !== 'undefined') lastVisitedArea = localStorage.getItem('lastVisitedArea');
  if (isLogin && lastVisitedArea && lastVisitedArea !== defaultCity) area = lastVisitedArea;

  return (
    <Dashboard>
      <Head>
        <title>Welcome to AllTheRooms.Analytics</title>
      </Head>
      <div className={styles.Background}>
        <div className={styles.Container}>
          <div className={styles.TextTitle}>What do you want to achieve today?</div>

          <div className={styles.SubTitle}>Choose from the products below to get started.</div>
          <div className={styles.SearchBarContainer}>
            <GeocodedAutocomplete
              onPickArea={processAreaPicked}
              emptyByDefault
              hideChevron={!isMobile}
              className={styles.GeocodedAutocomplete}
            />
          </div>
          <div className={styles.CardsContainer}>
            <CardLink
              className={styles.Card}
              type="Market"
              onClickLink={onClickRedirect(`${HISTORICAL_PATH}/${area}${!isLogin ? `?rd=1` : ''}`)}
            />
            <CardLink className={styles.Card} type="Score" onClickLink={onClickRedirect(SCORE_PATH)} />
            <CardLink className={styles.Card} type="Enterprise" onClickLink={onClickRedirect(ENTERPRISE_PATH)} />
          </div>
          <Banner
            className={styles.EnterpriseBanner}
            label="Looking for Custom Data?"
            // eslint-disable-next-line max-len
            paragraph="We also provide granular and raw data feeds – putting you in the driver’s seat to extract your own insights about the short term rental market."
            href={marketingURLS.ENTERPRISE}
            img="/images/enterprise-home.svg"
            buttonText="Get in touch "
          />
        </div>
      </div>
    </Dashboard>
  );
};
