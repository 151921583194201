/* eslint-disable max-len */
export type CardVariation = {
  title: string;
  description: string;
  image: string;
  icon: string;
  longDescription: string;
  isNewCard: boolean;
  isBeta: boolean;
};

export const CardVariations: { [index: string]: CardVariation } = {
  Market: {
    title: 'Market Intelligence',
    description: 'Everything you need to understand your short-term rental market.',
    image: '/images/splash-card-link-market.png',
    icon: '/images/icons/dashboard.svg',
    longDescription:
      'Everything you need to understand your short-term rental market. Complete with ADRs, Occupancy Rates, and Revenue, broken down by property type and location.',
    isNewCard: false,
    isBeta: false,
  },
  Score: {
    title: 'Property Score',
    description: 'Get your latest score every month.',
    image: '/images/splash-card-link-score.png',
    icon: '/images/icons/love-meter.svg',
    longDescription:
      'Get your latest score every month. We analyze bookings, earnings and reviews of all the vacation rentals in your city and generate a personalized property score.',
    isNewCard: false,
    isBeta: false,
  },
  Competitive: {
    title: 'Competitive Intelligence',
    description: 'Track yout property’s performance. Only available for Desktop.',
    image: '/images/splash-card-link-competitive.png',
    icon: '/images/icons/competitive-intelligence-dashboard-icon-beta.svg',
    longDescription:
      'Track your property’s performance, benchmark against your local competition, identify areas of improvement and competitive gaps.',
    isNewCard: true,
    isBeta: true,
  },
  Enterprise: {
    title: 'Enterprise',
    description: 'Curated reports for every use-case.',
    image: '/images/splash-competitive.png',
    icon: '/images/icons/profesional2.svg',
    longDescription:
      'Enterprise solutions with global data coverage. Over 7 years of historical data, 50 data points daily for all vacation rentals. Tailored reporting for large firms and enterprises.',
    isNewCard: false,
    isBeta: false,
  },
};
